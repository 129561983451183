import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/psdev/Documents/gitdev/workspace/working/myproject/hds25-gatby4-anndream-website-2022/site/src/components/layout.js";
import { Link } from 'hds-react';
import ExternalLink from '../../../components/ExternalLink';
import LeadParagraph from '../../../components/LeadParagraph';
import Image from '../../../components/Image';
import InternalLink from '../../../components/InternalLink';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const IconCheck = makeShortcode("IconCheck");
const IconError = makeShortcode("IconError");
const StatusLabel = makeShortcode("StatusLabel");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "building-forms",
      "style": {
        "position": "relative"
      }
    }}>{`Building forms`}<a parentName="h1" {...{
        "href": "#building-forms",
        "aria-label": "building forms permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h1>
    <LeadParagraph mdxType="LeadParagraph">
  Forms are groups of controls that allow the user to input, modify and select data values or configure options.
    </LeadParagraph>
    <h2 {...{
      "id": "principles",
      "style": {
        "position": "relative"
      }
    }}>{`Principles`}<a parentName="h2" {...{
        "href": "#principles",
        "aria-label": "principles permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <p>{`Forms `}<em parentName="p">{`should`}</em>{` be designed to be as clear and straightforward as possible, to allow the user to complete the task quickly.`}</p>
    <div className="guideline-do" style={{
      "background": "var(--color-success-light)",
      "padding": "var(--spacing-s)",
      "marginBottom": "var(--spacing-layout-2-xs)",
      "maxWidth": "600px"
    }}>
  <div className="guideline-do-label" style={{
        "color": "var(--color-success)",
        "marginBottom": "var(--spacing-s)"
      }}>
    <IconCheck style={{
          marginRight: 'var(--spacing-3-xs)',
          verticalAlign: 'middle'
        }} size="s" mdxType="IconCheck" />
    <strong>Do</strong>
  </div>
  <ul style={{
        "marginBottom": "0"
      }}>
    <li>
      <strong>Only ask for absolutely necessary information</strong>
      <ul style={{
            "marginTop": "0"
          }}>
        <li>Respect the user’s privacy and GDPR regulations</li>
        <li>Be sensitive for example about asking the user’s age or gender</li>
        <li>Make sure you the service is allowed to collect and hold the data, for example about an underaged user.</li>
      </ul>
    </li>
    <li>
      <strong>Follow a logical, predictable order</strong> (for example first name, last name).
    </li>
    <li>
      <strong>Group related tasks</strong> under section titles to provide more context and make the interface easier to
      scan.
    </li>
    <li>
      <strong>Provide clear, descriptive headlines, fieldset legends and labels</strong> to help the user easily scan
      and get the gist of the form.
    </li>
    <li>
      <strong>Divide long forms into smaller steps </strong>to make it easier to understand and validate.
    </li>
    <li>
      <strong>Enable autocompleting values</strong> to provide automated assistance in filling out form field values.
      <ul style={{
            "marginTop": "0"
          }}>
        <li>Provide an appropriate autocomplete attribute to fields.</li>
      </ul>
    </li>
    <li>
      <strong>Pre-populate known values</strong> if possible to make filling the form easier.
      <ul style={{
            "marginTop": "0"
          }}>
        <li>
          The user <em>should not</em> be made to input the same information twice.
        </li>
      </ul>
    </li>
    <li>
      <strong>Pre-populate known values</strong> if possible to make filling the form easier.
      <ul style={{
            "marginTop": "0"
          }}>
        <li>
          The user <em>should not</em> be made to input the same information twice.
        </li>
      </ul>
    </li>
    <li>
      <strong>Use gender-neutral language.</strong>
      <ul style={{
            "marginTop": "0"
          }}>
        <li>
          When asking for gender, refer to the 
          [example in the gender-neutrality chapter](#asking-for-gender)
          .
        </li>
      </ul>
    </li>
  </ul>
    </div>
    <div className="guideline-dont" style={{
      "background": "var(--color-error-light)",
      "padding": "var(--spacing-s)",
      "marginBottom": "var(--spacing-layout-2-xs)",
      "maxWidth": "600px"
    }}>
  <div className="guideline-dont-label" style={{
        "color": "var(--color-error)",
        "marginBottom": "var(--spacing-s)"
      }}>
    <IconError style={{
          marginRight: 'var(--spacing-3-xs)',
          verticalAlign: 'middle'
        }} size="s" mdxType="IconError" />
    <strong>Don't</strong>
  </div>
  <ul style={{
        "marginBottom": "0"
      }}>
    <li>
      The user <em>should not</em> be made to constantly switch between keyboard and mouse while filling the form.
    </li>
  </ul>
    </div>
    <h2 {...{
      "id": "accessibility",
      "style": {
        "position": "relative"
      }
    }}>{`Accessibility`}<a parentName="h2" {...{
        "href": "#accessibility",
        "aria-label": "accessibility permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <div className="guideline-dont" style={{
      "background": "var(--color-error-light)",
      "padding": "var(--spacing-s)",
      "marginBottom": "var(--spacing-layout-2-xs)",
      "maxWidth": "600px"
    }}>
  <div className="guideline-dont-label" style={{
        "color": "var(--color-error)",
        "marginBottom": "var(--spacing-s)"
      }}>
    <IconError style={{
          marginRight: 'var(--spacing-3-xs)',
          verticalAlign: 'middle'
        }} size="s" mdxType="IconError" />
    <strong>Don't</strong>
  </div>
  <strong>
    A change in a form controls value or state <em>must never </em>trigger a change in page <em>context</em>.
  </strong>
  <ul style={{
        "marginBottom": "0"
      }}>
    <li>
      <strong>
        Form controls <em>must</em> only be used to change page <em>content</em>.
      </strong>
    </li>
    <li>
      <strong>
        To trigger any change in <em>context</em>, an button <em>must</em> be used.
      </strong>
    </li>
    <li>
      <em>A change in from control</em> happens for example when:
      <ul style={{
            "marginTop": "0"
          }}>
        <li>
          the control receives focus (
          <ExternalLink href="https://www.w3.org/TR/UNDERSTANDING-WCAG20/consistent-behavior-receive-focus.html" mdxType="ExternalLink">
            WCAG 2.1 success criteria 3.2.1 On focus
          </ExternalLink>
          )
        </li>
        <li>
          the value is changed (
          <ExternalLink href="https://www.w3.org/TR/UNDERSTANDING-WCAG20/consistent-behavior-unpredictable-change.html" mdxType="ExternalLink">
            WCAG 2.1 success criteria 3.2.2 On input
          </ExternalLink>
          )
        </li>
      </ul>
    </li>
    <li>
      A change in <em>content</em> is for example:
      <ul style={{
            "marginTop": "0"
          }}>
        <li>Visual changes, such as an expanding an outline </li>
        <li>Opening a dynamic menu or dropdown</li>
        <li>
          Adding/removing or showing/hiding form controls based on previous selections (for example showing a set of
          additional questions related to a previous selection)
        </li>
      </ul>
    </li>
    <li>
      A change in <em>context</em> is for example:
      <ul style={{
            "marginTop": "0"
          }}>
        <li>
          A major change in content or layout that alters the meaning of the page, or makes it look like a different
          page (for example significantly re-arranging the content of a page).
        </li>
        <li>Moving focus to a different component</li>
        <li>Opening a new browser window or tab</li>
      </ul>
    </li>
  </ul>
    </div>
    <hr></hr>
    <h2 {...{
      "id": "choosing-the-right-component",
      "style": {
        "position": "relative"
      }
    }}>{`Choosing the right component`}<a parentName="h2" {...{
        "href": "#choosing-the-right-component",
        "aria-label": "choosing the right component permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <p>{`Different form controls have all different functionalities and specific use cases. Here are some guidelines on when each form control `}<em parentName="p">{`should`}</em>{` be used.`}</p>
    <h3 {...{
      "id": "entering-text",
      "style": {
        "position": "relative"
      }
    }}>{`Entering text`}<a parentName="h3" {...{
        "href": "#entering-text",
        "aria-label": "entering text permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <h4 {...{
      "id": "text-inputs",
      "style": {
        "position": "relative"
      }
    }}>{`Text inputs`}<a parentName="h4" {...{
        "href": "#text-inputs",
        "aria-label": "text inputs permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`Enable the user to `}<strong parentName="p">{`input a freeform string of text that does not have a specific format.`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Control`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Usage`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Examples`}</th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><Image src="/images/patterns/form/building/form-choosing-textinput@2x.png" alt="Text input" style={{
              "maxWidth": "250px"
            }} viewable mdxType="Image" /></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Text input`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Entering short strings of texts or numbers no more than a few words long.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><Image src="/images/patterns/form/building/form-choosing-textarea@2x.png" alt="Text area" style={{
              "maxWidth": "250px"
            }} viewable mdxType="Image" /></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Text area`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Entering multiple lines of text.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Messages, descriptions, feedback, support requests`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`[Table 1:Text inputs available in the HDS]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <h4 {...{
      "id": "data-inputs-for-specific-types-and-formats",
      "style": {
        "position": "relative"
      }
    }}>{`Data inputs for specific types and formats`}<a parentName="h4" {...{
        "href": "#data-inputs-for-specific-types-and-formats",
        "aria-label": "data inputs for specific types and formats permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`Enable user to `}<strong parentName="p">{`input textual data of a specific type`}</strong>{`. While this kind of data could be inputted to a text input, it is always recommended to use a data-specific input if it is available.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Control`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Usage`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Examples`}</th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><Image src="/images/patterns/form/building/form-choosing-numberinput@2x.png" alt="Number input" style={{
              "maxWidth": "250px"
            }} viewable mdxType="Image" /></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number input`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Entering a number.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Prices, amounts, numbers with units`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><Image src="/images/patterns/form/building/form-choosing-dateinput@2x.png" alt="Date input" style={{
              "maxWidth": "250px"
            }} viewable mdxType="Image" /></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date input`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Entering a date.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Event dates, birthdays, dates as filters`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><Image src="/images/patterns/form/building/form-choosing-timeinput@2x.png" alt="Time input" style={{
              "maxWidth": "250px"
            }} viewable mdxType="Image" /></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Time input`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Entering a time in a clock format.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Event start time, parking end time`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><Image src="/images/patterns/form/building/form-choosing-phonenumberinput@2x.png" alt="Phone number input" style={{
              "maxWidth": "250px"
            }} viewable mdxType="Image" /></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Phone number input`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Entering a phone number.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Phone numbers in different locale formats`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><Image src="/images/patterns/form/building/form-choosing-passwordinput@2x.png" alt="Password input" style={{
              "maxWidth": "250px"
            }} viewable mdxType="Image" /></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Password input`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Entering a password or other sensitive data.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Passwords, passphrases, sensitive personal data`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`[Table 2:Data inputs available in the HDS]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <div className="guideline-do" style={{
      "background": "var(--color-success-light)",
      "padding": "var(--spacing-s)",
      "marginBottom": "var(--spacing-layout-2-xs)",
      "maxWidth": "600px"
    }}>
  <div className="guideline-do-label" style={{
        "color": "var(--color-success)",
        "marginBottom": "var(--spacing-s)"
      }}>
    <IconCheck style={{
          marginRight: 'var(--spacing-3-xs)',
          verticalAlign: 'middle'
        }} size="s" mdxType="IconCheck" />
    <strong>Do</strong>
  </div>
  <ul style={{
        "marginBottom": "0"
      }}>
    <li>
      Adjust the width to reflect the intended length of the content.
      <ul style={{
            "marginTop": "0"
          }}>
        <li>
          Align the width to the <InternalLink href="/foundation/guidelines/grid" mdxType="InternalLink">responsive column grid</InternalLink>.
        </li>
        <li>If an input is too long to be fully displayed in the field, truncate the value with ellipsis.</li>
      </ul>
    </li>
    <li>
      Always use a dedicated input type if it is available. E.g. use the phone number input for inputting phone numbers.
    </li>
    <li>Make sure users can enter their information even on smaller screen sizes and mobile devices.</li>
  </ul>
    </div>
    <hr></hr>
    <h3 {...{
      "id": "selecting-from-pre-defined-options",
      "style": {
        "position": "relative"
      }
    }}>{`Selecting from pre-defined options`}<a parentName="h3" {...{
        "href": "#selecting-from-pre-defined-options",
        "aria-label": "selecting from pre defined options permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`Enable the user to enter an input by `}<strong parentName="p">{`selecting from a set of pre-defined options`}</strong>{`.`}</p>
    <p>{`Each selection control has a specific use case. To learn which one to use, see `}<InternalLink href="/foundation/guidelines/checkbox-radiobutton-toggle" mdxType="InternalLink">{`HDS Guideline Checkboxes, radio buttons, or toggles documentation page.`}</InternalLink></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Control`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Usage`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Examples`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><Image src="/images/patterns/form/building/form-choosing-select-checkbox@2x.png" alt="Checkbox" style={{
              "maxWidth": "250px"
            }} viewable mdxType="Image" /></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Checkbox`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Selecting a single option.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Agreeing to terms and conditions`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><Image src="/images/patterns/form/building/form-choosing-select-checkboxgroup@2x.png" alt="Checkbox group" style={{
              "maxWidth": "250px"
            }} viewable mdxType="Image" /></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Checkbox group`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Selecting one or more options from a short list.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul style={{
              "marginBottom": "0"
            }}><li>{`Adding optional items`}</li><li>{`Selecting all that apply`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><Image src="/images/patterns/form/building/form-choosing-select-radio@2x.png" alt="Radio button group" style={{
              "maxWidth": "250px"
            }} viewable mdxType="Image" /></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Radio button group`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Selecting a single option from mutually exclusive choices.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Picking a type`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><Image src="/images/patterns/form/building/form-choosing-select-togglebutton@2x.png" alt="Toggle button" style={{
              "maxWidth": "250px"
            }} viewable mdxType="Image" /></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Toggle button`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Choosing between binary options.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Changing a setting, switching a theme`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`[Table 3:Selection components available in the HDS]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <div className="guideline-do" style={{
      "background": "var(--color-success-light)",
      "padding": "var(--spacing-s)",
      "marginBottom": "var(--spacing-layout-2-xs)",
      "maxWidth": "600px"
    }}>
  <div className="guideline-do-label" style={{
        "color": "var(--color-success)",
        "marginBottom": "var(--spacing-s)"
      }}>
    <IconCheck style={{
          marginRight: 'var(--spacing-3-xs)',
          verticalAlign: 'middle'
        }} size="s" mdxType="IconCheck" />
    <strong>Do</strong>
  </div>
  <ul style={{
        "marginBottom": "0"
      }}>
    <li>
      <strong>Related checkboxes and radio buttons <em>must</em> be grouped to a fieldset </strong>
      with a legend stating the selection being made made.
    </li>
  </ul>
    </div>
    <hr></hr>
    <h4 {...{
      "id": "long-list-of-choices",
      "style": {
        "position": "relative"
      }
    }}>{`Long list of choices`}<a parentName="h4" {...{
        "href": "#long-list-of-choices",
        "aria-label": "long list of choices permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Control`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Usage`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Examples`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><Image src="/images/patterns/form/building/form-choosing-dropdown@2x.png" alt="Dropdown" style={{
              "maxWidth": "250px"
            }} viewable mdxType="Image" /></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Dropdown select`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul style={{
              "marginBottom": "0"
            }}><li>{`Selecting a single option.`}</li><li>{`4–7 choices.`}</li><li>{`Comparable to a radio button group.`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><Image src="/images/patterns/form/building/form-choosing-dropdown-multi@2x.png" alt="Dropdown multi-select" style={{
              "maxWidth": "250px"
            }} viewable mdxType="Image" /></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Dropdown`}<br />{`multi-select`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul style={{
              "marginBottom": "0"
            }}><li>{`Selecting multiple options `}</li><li>{`4–7 choices.`}</li><li>{`Comparable to a checkbox group.`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><Image src="/images/patterns/form/building/form-choosing-combobox@2x.png" alt="Combobox" style={{
              "maxWidth": "250px"
            }} viewable mdxType="Image" /></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Combobox select`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul style={{
              "marginBottom": "0"
            }}><li>{`Selecting single option.`}</li><li>{`more than 8 choices.`}</li><li>{`Allows `}<strong>{`filtering options`}</strong>{` with text search.`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Choosing for example city of residence, place of birth, or language preference`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><Image src="/images/patterns/form/building/form-choosing-combobox-multi@2x.png" alt="Combobox multi-select" style={{
              "maxWidth": "250px"
            }} viewable mdxType="Image" /></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Combobox`}<br />{`multi-select`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ul style={{
              "marginBottom": "0"
            }}><li>{`Selecting multiple options.`}</li><li>{`more than 8 choices.`}</li><li>{`Allows `}<strong>{`filtering options.`}</strong>{` with text search.`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`[Table 4:Selection components for a large number of items available in the HDS]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <div className="guideline-dont" style={{
      "background": "var(--color-error-light)",
      "padding": "var(--spacing-s)",
      "marginBottom": "var(--spacing-layout-2-xs)",
      "maxWidth": "600px"
    }}>
  <div className="guideline-dont-label" style={{
        "color": "var(--color-error)",
        "marginBottom": "var(--spacing-s)"
      }}>
    <IconError style={{
          marginRight: 'var(--spacing-3-xs)',
          verticalAlign: 'middle'
        }} size="s" mdxType="IconError" />
    <strong>Don't</strong>
  </div>
  <ul style={{
        "marginBottom": "0"
      }}>
    <li>
      <strong>Dropdown or combobox <em>must</em> never be used for listing links. </strong>
      Links cause a change in context (see <a href="#accessibility">form accessibility principles</a>)
    </li>
  </ul>
    </div>
    <hr></hr>
    <h3 {...{
      "id": "performing-actions",
      "style": {
        "position": "relative"
      }
    }}>{`Performing actions`}<a parentName="h3" {...{
        "href": "#performing-actions",
        "aria-label": "performing actions permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`To enable the user to `}<strong parentName="p">{`perform actions effecting the whole form or a group of form components`}</strong>{`, buttons `}<em parentName="p">{`should`}</em>{` be used.`}</p>
    <p>{`Individual form controls can also have action buttons attached to them, that let the user execute commands or other actions within the form element.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Control`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Usage`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Examples`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><Image src="/images/patterns/form/building/form-choosing-submit@2x.png" alt="Primary action button" style={{
              "maxWidth": "250px"
            }} viewable mdxType="Image" /></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Primary action`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Submitting inputted data`}</strong>{` to the defined form handler on the server.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Submit`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><Image src="/images/patterns/form/building/form-choosing-secondary@2x.png" alt="Secondary action button" style={{
              "maxWidth": "250px"
            }} viewable mdxType="Image" /></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Secondary action`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Allowing user to perform `}<strong parentName="td">{`important and form-wide actions`}</strong>{` before submitting: `}<ul style={{
              "marginBottom": "0"
            }}><li>{`Navigating multi-page forms`}</li><li>{`Making batch changes to the form fields`}</li><li>{`Cancelling the process and returning to previous view.`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul style={{
              "marginBottom": "0"
            }}><li>{`Next/previous page`}</li><li>{`Batch clear form fields.`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><Image src="/images/patterns/form/building/form-choosing-supplementary@2x.png" alt="Supplementary action button" style={{
              "maxWidth": "250px"
            }} viewable mdxType="Image" /></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Supplementary action`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Allows user to perform: `}<ul style={{
              "marginBottom": "0"
            }}><li><strong>{`Auxiliary functionalities`}</strong>{` that help the user but are not essential in filling the form`}</li><li><strong>{`Inline actions`}</strong>{` that affect smaller sections of a form (for example a fieldset)`}</li></ul></td>
          <td parentName="tr" {...{
            "align": null
          }}><ul style={{
              "marginBottom": "0"
            }}><li>{`Printing`}</li><li>{`Saving a draft`}</li><li>{`Adding or removing instances or rows to a data list (for example persons to a participant list)`}</li><li>{`Inputting data to a form control from an outside source (for example uploading an image, or fetching a location from Google maps)`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`[Table 5:Buttons for triggering actions available in the HDS]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <div className="guideline-do" style={{
      "background": "var(--color-success-light)",
      "padding": "var(--spacing-s)",
      "marginBottom": "var(--spacing-layout-2-xs)",
      "maxWidth": "600px"
    }}>
  <div className="guideline-do-label" style={{
        "color": "var(--color-success)",
        "marginBottom": "var(--spacing-s)"
      }}>
    <IconCheck style={{
          marginRight: 'var(--spacing-3-xs)',
          verticalAlign: 'middle'
        }} size="s" mdxType="IconCheck" />
    <strong>Do</strong>
  </div>
  <ul style={{
        "marginBottom": "0"
      }}>
    <li>
      <strong>
        All static forms <em>must</em> have a submit button.
      </strong>
      <ul>
        <li>
          When form controls are used to dynamically change content (for example filtering a dataset), submit button is
          not necessary.
        </li>
        <li>
          The submit button <em>must</em> be the only primary action in a form.
        </li>
      </ul>
    </li>
    <li>
      <strong>
        The submit button <em>should</em> be the last element of the form
      </strong>
      <ul>
        <li>
          All required fields and important information considering the form <em>must</em> be placed before the submit
          button.
        </li>
        <li>
          For example: user is required to approve terms or conditions, or read “small print” before submitting the
          form.
        </li>
      </ul>
    </li>
    <li>
      <strong>Keep it simple.</strong> Don't confuse the user by overcomplicating the form.
      <ul>
        <li>
          The number of secondary and supplementary actions in a form <em>should</em> be kept as few as possible.
        </li>
        <li>
          Actions <em>should</em> be grouped together, to help the user understand all the actions available in the form
          at a glance.
        </li>
        <li>Icons can be used to differentiate multiple actions.</li>
      </ul>
    </li>
    <li>
      <strong>Prevent accidental loss of data when performing destructive actions.</strong> For example clearing all
      form fields.
      <ul>
        <li>
          The user <em>should</em> be asked to confirm the action (for example with a modal).
        </li>
        <li>
          In multi-page forms the action <em>should</em> only be performed to the current page.
        </li>
        <li>
          After clearing all form fields, the focus <em>should</em> be moved to the beginning of the form.
        </li>
        <li>
          When performing non destructive actions, the user can be informed about the change made, for example with a
          toast notification.
        </li>
      </ul>
    </li>
    <li>
      Supplementary actions <em>should</em> always have both text label and icon, to differentiate them from text links.
      <ul>
        <li>The Small button variant can be used for inline actions.</li>
      </ul>
    </li>
  </ul>
    </div>
    <div className="guideline-dont" style={{
      "background": "var(--color-error-light)",
      "padding": "var(--spacing-s)",
      "marginBottom": "var(--spacing-layout-2-xs)",
      "maxWidth": "600px"
    }}>
  <div className="guideline-dont-label" style={{
        "color": "var(--color-error)",
        "marginBottom": "var(--spacing-s)"
      }}>
    <IconError style={{
          marginRight: 'var(--spacing-3-xs)',
          verticalAlign: 'middle'
        }} size="s" mdxType="IconError" />
    <strong>Don't</strong>
  </div>
  <ul style={{
        "marginBottom": "0"
      }}>
    <li>
      <strong>The submit button <em>must</em> not be placed outside the form element. </strong>
      For example inside the footer.
    </li>
    <li>
      <strong>
        Actions performed inside a form <em>must</em> not affect elements outside the form without confirming the action
        with submit.
      </strong>
    </li>
  </ul>
    </div>
    <hr></hr>
    <h2 {...{
      "id": "basic-elements-of-a-form-control",
      "style": {
        "position": "relative"
      }
    }}>{`Basic elements of a form control`}<a parentName="h2" {...{
        "href": "#basic-elements-of-a-form-control",
        "aria-label": "basic elements of a form control permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <p>{`There are several different types of form controls, but they share similar basic elements and functionalities:`}</p>
    <Image src="/images/patterns/form/building/form-elements-contols@2x.png" alt="Form control" style={{
      "maxWidth": "376px"
    }} viewable mdxType="Image" />
    <ol>
      <li parentName="ol">{`Input or selection`}</li>
      <li parentName="ol">{`Label`}</li>
      <li parentName="ol">{`Required field indicator`}</li>
      <li parentName="ol">{`Icon`}</li>
      <li parentName="ol">{`Action button`}</li>
    </ol>
    <hr></hr>
    <h3 {...{
      "id": "1-input-field-or-selection",
      "style": {
        "position": "relative"
      }
    }}>{`1. Input field or selection`}<a parentName="h3" {...{
        "href": "#1-input-field-or-selection",
        "aria-label": "1 input field or selection permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`The actual `}<strong parentName="p">{`interactive control element`}</strong>{` that allows the user to input or information or select an option.`}</p>
    <Image src="/images/patterns/form/building/form-elements-contol@2x.png" alt="Form control input and selection" style={{
      "maxWidth": "376px"
    }} viewable mdxType="Image" />
    <div className="guideline-do" style={{
      "background": "var(--color-success-light)",
      "padding": "var(--spacing-s)",
      "marginBottom": "var(--spacing-layout-2-xs)",
      "maxWidth": "600px"
    }}>
  <div className="guideline-do-label" style={{
        "color": "var(--color-success)",
        "marginBottom": "var(--spacing-s)"
      }}>
    <IconCheck style={{
          marginRight: 'var(--spacing-3-xs)',
          verticalAlign: 'middle'
        }} size="s" mdxType="IconCheck" />
    <strong>Do</strong>
  </div>
  <p>
    <strong>Adjust the size</strong> of the input field or selection to indicate the length of the preferred input or
    average option.
  </p>
    </div>
    <hr></hr>
    <h3 {...{
      "id": "2-label",
      "style": {
        "position": "relative"
      }
    }}>{`2. Label`}<a parentName="h3" {...{
        "href": "#2-label",
        "aria-label": "2 label permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p><strong parentName="p">{`The name of the form control`}</strong>{` that clearly states the purpose of the control or what information the user is required to input`}</p>
    <Image src="/images/patterns/form/building/form-elements-label@2x.png" alt="Form control label" style={{
      "maxWidth": "376px"
    }} viewable mdxType="Image" />
    <div className="guideline-do" style={{
      "background": "var(--color-success-light)",
      "padding": "var(--spacing-s)",
      "marginBottom": "var(--spacing-layout-2-xs)",
      "maxWidth": "600px"
    }}>
  <div className="guideline-do-label" style={{
        "color": "var(--color-success)",
        "marginBottom": "var(--spacing-s)"
      }}>
    <IconCheck style={{
          marginRight: 'var(--spacing-3-xs)',
          verticalAlign: 'middle'
        }} size="s" mdxType="IconCheck" />
    <strong>Do</strong>
  </div>
  <ul style={{
        "marginBottom": "0"
      }}>
    <li>
      <strong>
        All form components <em>must</em> have a text label.
      </strong>
      <ul>
        <li>The label can also be substituted with an action button added to the form control. </li>
        <li>
          If an action button is used to substitute a label, it <em>must</em> have a text label, not just an icon.
        </li>
        <li>An exception is made in the HDS navigation search component which follows a widely used pattern.</li>
      </ul>
    </li>
    <li>
      <strong>
        The label <em>must</em> be always visible to the user.
      </strong>
    </li>
    <li>
      Be descriptive.
      <ul>
        <li>
          <strong>
            The user <em>must</em> be able to understand the purpose of the form control from the label only.
          </strong>
        </li>
      </ul>
    </li>
    <li>
      Keep it short.
      <ul>
        <li>Preferably use less than five words.</li>
        <li>
          If the user needs more information for filling the information, it <em>should</em> be provided with assistive
          text or tooltip.
        </li>
      </ul>
    </li>
  </ul>
    </div>
    <div className="guideline-dont" style={{
      "background": "var(--color-error-light)",
      "padding": "var(--spacing-s)",
      "marginBottom": "var(--spacing-layout-2-xs)",
      "maxWidth": "600px"
    }}>
  <div className="guideline-dont-label" style={{
        "color": "var(--color-error)",
        "marginBottom": "var(--spacing-s)"
      }}>
    <IconError style={{
          marginRight: 'var(--spacing-3-xs)',
          verticalAlign: 'middle'
        }} size="s" mdxType="IconError" />
    <strong>Don't</strong>
  </div>
  <ul style={{
        "marginBottom": "0"
      }}>
    <li>Use ALL CAPS.</li>
    <li>Use a colon : after label names.</li>
  </ul>
    </div>
    <hr></hr>
    <h3 {...{
      "id": "3-required-field-indicator",
      "style": {
        "position": "relative"
      }
    }}>{`3. Required field indicator`}<a parentName="h3" {...{
        "href": "#3-required-field-indicator",
        "aria-label": "3 required field indicator permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`Marking that indicates a form control for which `}<strong parentName="p">{`the user `}<em parentName="strong">{`must`}</em>{` enter a value`}</strong>{` in order to successfully submit the form.`}</p>
    <Image src="/images/patterns/form/building/form-elements-required@2x.png" alt="Form control required indicator" style={{
      "maxWidth": "376px"
    }} viewable mdxType="Image" />
    <div className="guideline-do" style={{
      "background": "var(--color-success-light)",
      "padding": "var(--spacing-s)",
      "marginBottom": "var(--spacing-layout-2-xs)",
      "maxWidth": "600px"
    }}>
  <div className="guideline-do-label" style={{
        "color": "var(--color-success)",
        "marginBottom": "var(--spacing-s)"
      }}>
    <IconCheck style={{
          marginRight: 'var(--spacing-3-xs)',
          verticalAlign: 'middle'
        }} size="s" mdxType="IconCheck" />
    <strong>Do</strong>
  </div>
  <ul style={{
        "marginBottom": "0"
      }}>
    <li>
      <strong>
        aria-required="true" attribute <em>must</em> be always provided for required fields
      </strong>
      , even if they are not marked visually.
    </li>
    <li>
      If your form has at least one required field, explain the asterisk before the form. E.g. "All fields marked with *
      are required".
    </li>
    <li>
      If all fields in the form are required
      <ul>
        <li>
          This <em>should</em> be stated in the form-wide instructions at the beginning of the form.
        </li>
        <li>The submit button can also be disabled before all fields are filled.</li>
      </ul>
    </li>
  </ul>
    </div>
    <hr></hr>
    <h3 {...{
      "id": "4-icon",
      "style": {
        "position": "relative"
      }
    }}>{`4. Icon`}<a parentName="h3" {...{
        "href": "#4-icon",
        "aria-label": "4 icon permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`Icon is a `}<strong parentName="p">{`supplementary visual representation of the label`}</strong>{` displayed inside the form field.`}</p>
    <p>{`Icons can be used to:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Emphasise`}</strong>{` important form controls visually.`}</li>
      <li parentName="ul"><strong parentName="li">{`Differentiate`}</strong>{` similar form controls easier from each other.`}</li>
    </ul>
    <Image src="/images/patterns/form/building/form-elements-icon@2x.png" alt="Form control icon" style={{
      "maxWidth": "376px"
    }} viewable mdxType="Image" />
    <div className="guideline-do" style={{
      "background": "var(--color-success-light)",
      "padding": "var(--spacing-s)",
      "marginBottom": "var(--spacing-layout-2-xs)",
      "maxWidth": "600px"
    }}>
  <div className="guideline-do-label" style={{
        "color": "var(--color-success)",
        "marginBottom": "var(--spacing-s)"
      }}>
    <IconCheck style={{
          marginRight: 'var(--spacing-3-xs)',
          verticalAlign: 'middle'
        }} size="s" mdxType="IconCheck" />
    <strong>Do</strong>
  </div>
  <ul style={{
        "marginBottom": "0"
      }}>
    <li>
      Be descriptive.
      <ul>
        <li>Choose an icon that clearly describes the type fo input or purpose of the form control.</li>
        <li>Do not use arbitrary or vague icons just for visual purposes.</li>
      </ul>
    </li>
    <li>
      See <InternalLink href="/foundation/visual-assets/icons" mdxType="InternalLink">HDS icon guidelines</InternalLink> for more information about using and
      choosing icons.
    </li>
  </ul>
    </div>
    <div className="guideline-dont" style={{
      "background": "var(--color-error-light)",
      "padding": "var(--spacing-s)",
      "marginBottom": "var(--spacing-layout-2-xs)",
      "maxWidth": "600px"
    }}>
  <div className="guideline-dont-label" style={{
        "color": "var(--color-error)",
        "marginBottom": "var(--spacing-s)"
      }}>
    <IconError style={{
          marginRight: 'var(--spacing-3-xs)',
          verticalAlign: 'middle'
        }} size="s" mdxType="IconError" />
    <strong>Don't</strong>
  </div>
  <p>
    <strong>An icon <em>must</em> not be used to perform an action. </strong>
    Use an action button instead.
  </p>
    </div>
    <hr></hr>
    <h3 {...{
      "id": "5-action-button",
      "style": {
        "position": "relative"
      }
    }}>{`5. Action button`}<a parentName="h3" {...{
        "href": "#5-action-button",
        "aria-label": "5 action button permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`Some HDS form controls have actionable elements for `}<strong parentName="p">{`executing commands or other actions`}</strong>{` within the form element. They provide an additional functionality to the form control for example to:`}</p>
    <ul>
      <li parentName="ul">{`Execute a search with inputted value.`}</li>
      <li parentName="ul">{`Clearing all selections in multi-select dropdowns.`}</li>
    </ul>
    <p>{`Although the action button is visually similar to an icon, the implementation is different.`}</p>
    <Image src="/images/patterns/form/building/form-elements-action@2x.png" alt="Form control icon" style={{
      "maxWidth": "376px"
    }} viewable mdxType="Image" />
    <div className="guideline-dont" style={{
      "background": "var(--color-error-light)",
      "padding": "var(--spacing-s)",
      "marginBottom": "var(--spacing-layout-2-xs)",
      "maxWidth": "600px"
    }}>
  <div className="guideline-dont-label" style={{
        "color": "var(--color-error)",
        "marginBottom": "var(--spacing-s)"
      }}>
    <IconError style={{
          marginRight: 'var(--spacing-3-xs)',
          verticalAlign: 'middle'
        }} size="s" mdxType="IconError" />
    <strong>Don't</strong>
  </div>
  <p>
    In HDS form components, action buttons are used for predefined purposes and <em>should</em> not be customised.
  </p>
    </div>
    <hr></hr>
    <h2 {...{
      "id": "interacting-with-controls",
      "style": {
        "position": "relative"
      }
    }}>{`Interacting with controls`}<a parentName="h2" {...{
        "href": "#interacting-with-controls",
        "aria-label": "interacting with controls permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "active-and-disabled",
      "style": {
        "position": "relative"
      }
    }}>{`Active and disabled`}<a parentName="h3" {...{
        "href": "#active-and-disabled",
        "aria-label": "active and disabled permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`Form controls are either `}<em parentName="p">{`active`}</em>{` or `}<em parentName="p">{`disabled`}</em>{` depending on for example selections made in previous controls in the form, or user permissions.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Active form controls can be focused and given a value.`}</strong>{` Form components are active by default`}</li>
      <li parentName="ul"><strong parentName="li">{`Disabled form controls cannot be clicked or focused with keyboard.`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`The disabled attribute is be set for a form control to keep a user from using it until some other condition has been met (for example a text input can be disabled before a checkbox is selected).`}</li>
        </ul>
      </li>
    </ul>
    <Image src="/images/patterns/form/building/form-states-active-disabled@2x.png" alt="Active and disabled form controls" style={{
      "maxWidth": "376px"
    }} viewable mdxType="Image" />
    <div className="guideline-do" style={{
      "background": "var(--color-success-light)",
      "padding": "var(--spacing-s)",
      "marginBottom": "var(--spacing-layout-2-xs)",
      "maxWidth": "600px"
    }}>
  <div className="guideline-do-label" style={{
        "color": "var(--color-success)",
        "marginBottom": "var(--spacing-s)"
      }}>
    <IconCheck style={{
          marginRight: 'var(--spacing-3-xs)',
          verticalAlign: 'middle'
        }} size="s" mdxType="IconCheck" />
    <strong>Do</strong>
  </div>
  <ul style={{
        "marginBottom": "0"
      }}>
    <li>
      Disabled form controls can also be hidden from view but in most situations all fields – even the disabled ones –
      should be shown to the user.
      <ul>
        <li>Showing all fields makes easier or the user to understand all the possible actions in the form.</li>
        <li>Previously hidden fields suddenly appearing into a form can confuse the user.</li>
      </ul>
    </li>
    <li>
      As a rule of thumb, a disabled field <em>should</em> only be hidden, if the user
      <ul>
        <li>is not provided with a way to activate the field,</li>
        <li>does not have the permission to change the fields value</li>
      </ul>
    </li>
  </ul>
    </div>
    <hr></hr>
    <h3 {...{
      "id": "emptyunselected-and-filledselected",
      "style": {
        "position": "relative"
      }
    }}>{`Empty/unselected and filled/selected`}<a parentName="h3" {...{
        "href": "#emptyunselected-and-filledselected",
        "aria-label": "emptyunselected and filledselected permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`Allowing the user enter a value either by input or selection is the `}<strong parentName="p">{`form controls main purpose`}</strong>{`. Make it as easy and straightforward as possible for the user.`}</p>
    <Image src="/images/patterns/form/building/form-states-filled@2x.png" alt="Empty and filled text fields" style={{
      "maxWidth": "376px"
    }} viewable mdxType="Image" />
    <Image src="/images/patterns/form/building/form-states-selected@2x.png" alt="Unselected and selected form controls" style={{
      "maxWidth": "376px"
    }} viewable mdxType="Image" />
    <div className="guideline-do" style={{
      "background": "var(--color-success-light)",
      "padding": "var(--spacing-s)",
      "marginBottom": "var(--spacing-layout-2-xs)",
      "maxWidth": "600px"
    }}>
  <div className="guideline-do-label" style={{
        "color": "var(--color-success)",
        "marginBottom": "var(--spacing-s)"
      }}>
    <IconCheck style={{
          marginRight: 'var(--spacing-3-xs)',
          verticalAlign: 'middle'
        }} size="s" mdxType="IconCheck" />
    <strong>Do</strong>
  </div>
  <p>
    <strong>Help the user by minimising unnecessary and redundant work needed for filling the form. </strong>Especially
    long forms can become tedious to fill, especially, if the same information must be entered multiple times.
  </p>
  <ul style={{
        "marginBottom": "0"
      }}>
    <li>
      <strong>Provide a pre-selected default option</strong> if some option is known to be the most commonly used.
    </li>
    <li>
      <strong>Pre-populate known values if data is available.</strong>
      <ul>
        <li>
          The user <em>should</em> not be made to input the same information twice.
        </li>
      </ul>
    </li>
    <li>
      <strong>Enable autocompleting input</strong> to assist users filling forms by pre-filling field values based on
      earlier user input (for example the user’s address).
      <ul>
        <li>
          The appropriate input purpose <em>should</em> be defined with autocomplete attribute where applicable.
        </li>
        <li>
          The autocomplete attribute hints the browser that the field can be autocompleted, and the type of information
          required.
        </li>
        <li>
          See more about
          <ExternalLink href="https://www.w3.org/TR/WCAG21/#input-purposes" mdxType="ExternalLink">
            input purpose terms used for autocomplete
          </ExternalLink>
          .
        </li>
      </ul>
    </li>
  </ul>
    </div>
    <div className="guideline-dont" style={{
      "background": "var(--color-error-light)",
      "padding": "var(--spacing-s)",
      "marginBottom": "var(--spacing-layout-2-xs)",
      "maxWidth": "600px"
    }}>
  <div className="guideline-dont-label" style={{
        "color": "var(--color-error)",
        "marginBottom": "var(--spacing-s)"
      }}>
    <IconError style={{
          marginRight: 'var(--spacing-3-xs)',
          verticalAlign: 'middle'
        }} size="s" mdxType="IconError" />
    <strong>Don't</strong>
  </div>
  <p>
    <strong>
      Selections that demand the users consent, like approving terms and conditions <em>must</em> never be pre-selected
      as default.
    </strong>
  </p>
    </div>
    <hr></hr>
    <h2 {...{
      "id": "providing-instructions",
      "style": {
        "position": "relative"
      }
    }}>{`Providing instructions`}<a parentName="h2" {...{
        "href": "#providing-instructions",
        "aria-label": "providing instructions permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <p>{`To help the user enter the `}<strong parentName="p">{`right information in the right format`}</strong>{`, guidance can be provided on different levels:`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Form main and sub-heading`}</strong>{` summarise the purpose of the form`}</li>
      <li parentName="ol"><strong parentName="li">{`Form-wide instructions`}</strong>{` to help the user to better understand the functionality of the form`}</li>
      <li parentName="ol"><strong parentName="li">{`Contextual instructions`}</strong>{` for helping the user to fill specific form controls`}</li>
    </ol>
    <h3 {...{
      "id": "1-form-heading-and-sub-heading",
      "style": {
        "position": "relative"
      }
    }}>{`1. Form heading and sub-heading`}<a parentName="h3" {...{
        "href": "#1-form-heading-and-sub-heading",
        "aria-label": "1 form heading and sub heading permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`The form heading `}<strong parentName="p">{`summarises the purpose of the form`}</strong>{` and indicates the user where the form begins.`}</p>
    <ul>
      <li parentName="ul">{`The `}<em parentName="li">{`main heading should`}</em>{` clearly and concisely `}<strong parentName="li">{`describe the purpose of the form`}</strong>{`.`}
        <ul parentName="li">
          <li parentName="ul">{`Full page forms `}<em parentName="li">{`should`}</em>{` always have a main heading.`}</li>
          <li parentName="ul">{`From smaller inline forms embedded to page content, the heading can be omitted.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`If the purpose of the form cannot be sufficiently summarised by the main heading, it can `}<strong parentName="li">{`elaborated with a `}<em parentName="strong">{`sub-heading`}</em></strong>{`.`}</li>
    </ul>
    <Image src="/images/patterns/form/building/form-guiding-headings@2x.png" alt="Form headings" style={{
      "maxWidth": "432px"
    }} viewable mdxType="Image" />
    <div className="guideline-do" style={{
      "background": "var(--color-success-light)",
      "padding": "var(--spacing-s)",
      "marginBottom": "var(--spacing-layout-2-xs)",
      "maxWidth": "600px"
    }}>
  <div className="guideline-do-label" style={{
        "color": "var(--color-success)",
        "marginBottom": "var(--spacing-s)"
      }}>
    <IconCheck style={{
          marginRight: 'var(--spacing-3-xs)',
          verticalAlign: 'middle'
        }} size="s" mdxType="IconCheck" />
    <strong>Do</strong>
  </div>
  <ul style={{
        "marginBottom": "0"
      }}>
    <li>
      <strong>
        If the form has a main heading, it <em>must</em> be the first element
      </strong>
      <ul style={{
            "marginTop": "0"
          }}>
        <li>
          The main heading <em>should</em> preferably be h1 element, even if visually styled as a smaller level heading.
        </li>
      </ul>
    </li>
    <li>
      The the sub-heading <em>should</em> placed right after the main heading, and preferably be a h2 element.
    </li>
    <li>
      <strong>Keep it short and descriptive!</strong>
      <ul style={{
            "marginTop": "0"
          }}>
        <li>
          Main heading <em>should</em> be less than five words.
        </li>
        <li>
          Sub-heading <em>should</em> be less than two to three sentences.
        </li>
      </ul>
    </li>
  </ul>
    </div>
    <hr></hr>
    <h3 {...{
      "id": "2-form-wide-instructions",
      "style": {
        "position": "relative"
      }
    }}>{`2. Form-wide instructions`}<a parentName="h3" {...{
        "href": "#2-form-wide-instructions",
        "aria-label": "2 form wide instructions permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`To help the user better understand the functionality of the form, instructions and other `}<strong parentName="p">{`information about filling the form and required information`}</strong>{` should be provided in the beginning of the form.`}</p>
    <Image src="/images/patterns/form/building/form-guiding-instructions@2x.png" alt="Form-wide instructions" style={{
      "maxWidth": "432px",
      "display": "block"
    }} viewable mdxType="Image" />
    <Image src="/images/patterns/form/building/form-guiding-instructions-required@2x.png" alt="Form-wide instructions with required fields" style={{
      "maxWidth": "432px"
    }} viewable mdxType="Image" />
    <div className="guideline-do" style={{
      "background": "var(--color-success-light)",
      "padding": "var(--spacing-s)",
      "marginBottom": "var(--spacing-layout-2-xs)",
      "maxWidth": "600px"
    }}>
  <div className="guideline-do-label" style={{
        "color": "var(--color-success)",
        "marginBottom": "var(--spacing-s)"
      }}>
    <IconCheck style={{
          marginRight: 'var(--spacing-3-xs)',
          verticalAlign: 'middle'
        }} size="s" mdxType="IconCheck" />
    <strong>Do</strong>
  </div>
  <ul style={{
        "marginBottom": "0"
      }}>
    <li>
      The instructions should be placed after the heading and sub-heading, before the first form control or fieldeset.
    </li>
    <li>
      The inline notification component can be used for form-wide instructions if they contain something out of the
      ordinary.
    </li>
    <li>
      If your form has at least one required field, explain the asterisk before the form. E.g. "All fields marked with *
      are required".
    </li>
  </ul>
    </div>
    <hr></hr>
    <h3 {...{
      "id": "3-contextual-instructions",
      "style": {
        "position": "relative"
      }
    }}>{`3. Contextual instructions`}<a parentName="h3" {...{
        "href": "#3-contextual-instructions",
        "aria-label": "3 contextual instructions permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p><strong parentName="p">{`Instructions related to specific form component`}</strong>{` can be provided for the user in three different ways. They each have different uses and levels of importance:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Assistive text`}</strong>{` provides essential information`}</li>
      <li parentName="ul"><strong parentName="li">{`Tooltip`}</strong>{` provides context or background information`}</li>
      <li parentName="ul"><strong parentName="li">{`Placeholder`}</strong>{` acts as a supplementary visual aid`}</li>
    </ul>
    <h4 {...{
      "id": "a-assistive-text",
      "style": {
        "position": "relative"
      }
    }}>{`a) Assistive text`}<a parentName="h4" {...{
        "href": "#a-assistive-text",
        "aria-label": "a assistive text permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`A short secondary description that elaborates the label and helps the user to provide the `}<strong parentName="p">{`right information in the right format`}</strong>{`.`}</p>
    <p><strong parentName="p">{`The assistive text is always visible to the user, so any `}<em parentName="strong">{`essential`}</em>{` information the user needs to complete their task `}<em parentName="strong">{`must`}</em>{` be provided as assistive text.`}</strong></p>
    <ul>
      <li parentName="ul">{`Displayed below the form control`}</li>
      <li parentName="ul">{`Screen readers read the assistive text after the label when focusing the form element.`}</li>
    </ul>
    <Image src="/images/patterns/form/building/form-guiding-assistive@2x.png" alt="Assistive text" style={{
      "maxWidth": "376px"
    }} viewable mdxType="Image" />
    <div className="guideline-do" style={{
      "background": "var(--color-success-light)",
      "padding": "var(--spacing-s)",
      "marginBottom": "var(--spacing-layout-2-xs)",
      "maxWidth": "600px"
    }}>
  <div className="guideline-do-label" style={{
        "color": "var(--color-success)",
        "marginBottom": "var(--spacing-s)"
      }}>
    <IconCheck style={{
          marginRight: 'var(--spacing-3-xs)',
          verticalAlign: 'middle'
        }} size="s" mdxType="IconCheck" />
    <strong>Do</strong>
  </div>
  <ul style={{
        "marginBottom": "0"
      }}>
    <li>
      <strong>Keep it short and clear.</strong>
      <ul>
        <li>
          The user <em>should</em> be able to provide the right input based on the label and assistive text alone.
        </li>
        <li>
          Assistive text <em>should</em> not be longer than the form control and more than two rows.
        </li>
        <li>
          The assistive text <em>should</em> only be used for essential information. Avoid causing unnecessary cognitive
          overload for the user by giving too much information.
        </li>
      </ul>
    </li>
  </ul>
    </div>
    <h4 {...{
      "id": "b-tooltip",
      "style": {
        "position": "relative"
      }
    }}>{`b) Tooltip`}<a parentName="h4" {...{
        "href": "#b-tooltip",
        "aria-label": "b tooltip permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`A popup element that provides `}<strong parentName="p">{`context or background information`}</strong>{` that is “nice to have” but not essential.`}</p>
    <ul>
      <li parentName="ul"><em parentName="li">{`Should`}</em>{` be used to:`}
        <ul parentName="li">
          <li parentName="ul">{`Explain to users that may be unfamiliar with a particular form control.`}</li>
          <li parentName="ul">{`Offer a rationale for why certain information is needed.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`An action button is added on the right side of the label.`}</li>
      <li parentName="ul">{`The tooltip opens, when user clicks the action button.`}</li>
    </ul>
    <Image src="/images/patterns/form/building/form-guiding-tooltip@2x.png" alt="Tooltip" style={{
      "maxWidth": "464px"
    }} viewable mdxType="Image" />
    <div className="guideline-do" style={{
      "background": "var(--color-success-light)",
      "padding": "var(--spacing-s)",
      "marginBottom": "var(--spacing-layout-2-xs)",
      "maxWidth": "600px"
    }}>
  <div className="guideline-do-label" style={{
        "color": "var(--color-success)",
        "marginBottom": "var(--spacing-s)"
      }}>
    <IconCheck style={{
          marginRight: 'var(--spacing-3-xs)',
          verticalAlign: 'middle'
        }} size="s" mdxType="IconCheck" />
    <strong>Do</strong>
  </div>
  <ul style={{
        "marginBottom": "0"
      }}>
    <li>
      <strong>Be clear and concise.</strong>
      <ul>
        <li>
          Although tooltips can provide more information than the assistive text, they should preferably be less than
          two or three sentences long.
        </li>
      </ul>
    </li>
    <li>
      <strong>Provide a link to a separate page</strong>, if more detailed information is available.
      <ul>
        <li>
          Always open links from tooltips to a new window, so that the process of filling the form is not interrupted.
        </li>
      </ul>
    </li>
  </ul>
    </div>
    <div className="guideline-dont" style={{
      "background": "var(--color-error-light)",
      "padding": "var(--spacing-s)",
      "marginBottom": "var(--spacing-layout-2-xs)",
      "maxWidth": "600px"
    }}>
  <div className="guideline-dont-label" style={{
        "color": "var(--color-error)",
        "marginBottom": "var(--spacing-s)"
      }}>
    <IconError style={{
          marginRight: 'var(--spacing-3-xs)',
          verticalAlign: 'middle'
        }} size="s" mdxType="IconError" />
    <strong>Don't</strong>
  </div>
  <ul style={{
        "marginBottom": "0"
      }}>
    <li>
      <strong>Make the tooltip open on hover.</strong>
      <ul>
        <li>The user should be able to select the text inside the tooltip element.</li>
      </ul>
    </li>
    <li>
      <strong>
        Tooltip must not be the sole way to provide essential information that the user must know to fill a value.
      </strong>
    </li>
  </ul>
    </div>
    <h4 {...{
      "id": "c-placeholder-text",
      "style": {
        "position": "relative"
      }
    }}>{`c) Placeholder text`}<a parentName="h4" {...{
        "href": "#c-placeholder-text",
        "aria-label": "c placeholder text permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`Placeholder text is a `}<strong parentName="p">{`supplementary visual aid`}</strong>{` displayed inside the form control that can be used form making the form easier to fill.`}</p>
    <p>{`It can be used to provide the user `}<strong parentName="p">{`a hint of what to enter or what is selected`}</strong>{` with the form control:`}</p>
    <ul>
      <li parentName="ul">{`The preferred format of the input (for example Social security number: "E.g. 000000-123A”)`}</li>
      <li parentName="ul">{`An example value or option (for example City: “E.g. Helsinki”).`}</li>
    </ul>
    <Image src="/images/patterns/form/building/form-guiding-placeholder@2x.png" alt="Placeholder text" style={{
      "maxWidth": "376px"
    }} viewable mdxType="Image" />
    <div className="guideline-do" style={{
      "background": "var(--color-success-light)",
      "padding": "var(--spacing-s)",
      "marginBottom": "var(--spacing-layout-2-xs)",
      "maxWidth": "600px"
    }}>
  <div className="guideline-do-label" style={{
        "color": "var(--color-success)",
        "marginBottom": "var(--spacing-s)"
      }}>
    <IconCheck style={{
          marginRight: 'var(--spacing-3-xs)',
          verticalAlign: 'middle'
        }} size="s" mdxType="IconCheck" />
    <strong>Do</strong>
  </div>
  <ul style={{
        "marginBottom": "0"
      }}>
    <li>
      <strong>
        Write placeholder text in a way that it can immediately be recognised as not being an actual value.
      </strong>
      <ul>
        <li>
          Format examples <em>should</em> be generic rather than real values. If the placeholder seems like an actual
          value, the user can mistake the field to be already filled.
        </li>
        <li>
          Values examples <em>should</em> preferably start in a way that clearly indicates it is a placeholder. For
          example: Full name: “E.g. John Doe”, (in Finnish Koko nimi: “Esim. Matti Meikäläinen”)
        </li>
        <li>
          Leave the placeholder empty if an example value that would be generic enough to be recognised as placeholder
          can’t be found.
        </li>
        <li>
          Screen readers do not differentiate if the element is a placeholder or an actual value. Even though assistive
          technology users are usually accustomed to this, it can possibly cause confusion.
        </li>
      </ul>
    </li>
  </ul>
    </div>
    <div className="guideline-dont" style={{
      "background": "var(--color-error-light)",
      "padding": "var(--spacing-s)",
      "marginBottom": "var(--spacing-layout-2-xs)",
      "maxWidth": "600px"
    }}>
  <div className="guideline-dont-label" style={{
        "color": "var(--color-error)",
        "marginBottom": "var(--spacing-s)"
      }}>
    <IconError style={{
          marginRight: 'var(--spacing-3-xs)',
          verticalAlign: 'middle'
        }} size="s" mdxType="IconError" />
    <strong>Don't</strong>
  </div>
  <ul style={{
        "marginBottom": "0"
      }}>
    <li>
      <strong>Never use the placeholder as the only channel to provide crucial information.</strong>
      <ul>
        <li>
          <strong>
            Corresponding information <em>must</em> also be provided within the label or assistive text
          </strong>
        </li>
        <li>Placeholder disappears once the user begins to input data </li>
        <li>
          The way screen readers treat placeholders varies. Some read it only when no value has been inputted, some read
          it even when a value has been inputted.
        </li>
        <li>An exception is made in the HDS navigation search component which follows a widely used pattern.</li>
      </ul>
    </li>
  </ul>
    </div>
    <hr></hr>
    <h2 {...{
      "id": "providing-structure-and-hierarchy",
      "style": {
        "position": "relative"
      }
    }}>{`Providing structure and hierarchy`}<a parentName="h2" {...{
        "href": "#providing-structure-and-hierarchy",
        "aria-label": "providing structure and hierarchy permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <p>{`Forms can be made easier to understand for the user by providing visual and semantic structure in different ways.`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Consistent layout`}</strong>{` communicates the structure of the form clearly.`}</li>
      <li parentName="ol"><strong parentName="li">{`Grouping related tasks`}</strong>{` helps the user to understand what form controls belong together.`}</li>
      <li parentName="ol"><strong parentName="li">{`Segmenting with section headings`}</strong>{` helps the user to understand how form fields relate to each other and the whole.`}</li>
      <li parentName="ol"><strong parentName="li">{`Dividing long forms into separate steps`}</strong>{` reduces cognitive load with progressive disclosure.`}</li>
    </ol>
    <h3 {...{
      "id": "1-consistent-layout",
      "style": {
        "position": "relative"
      }
    }}>{`1. Consistent layout`}<a parentName="h3" {...{
        "href": "#1-consistent-layout",
        "aria-label": "1 consistent layout permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`Best practices for form layout.`}</p>
    <div className="guideline-do" style={{
      "background": "var(--color-success-light)",
      "padding": "var(--spacing-s)",
      "marginBottom": "var(--spacing-layout-2-xs)",
      "maxWidth": "600px"
    }}>
  <p>
    <IconCheck style={{
          marginRight: 'var(--spacing-3-xs)',
          verticalAlign: 'middle',
          color: 'var(--color-success)'
        }} size="s" mdxType="IconCheck" />
    <strong>Prefer single column vertical layout over multi-column layout.</strong>
  </p>
  <ul>
    <li>
      Stacking form controls vertically to form a "F-shaped" reading path through the form takes more space but makes
      the users path from control to control smoother.
    </li>
    <li>
      If the user has to jump between more than one columns, the structure of the form, and reading path can become hard
      to follow.
    </li>
  </ul>
  <Image src="/images/patterns/form/building/form-layout-vertical@2x.png" alt="A F-shaped reading path through the form" style={{
        "maxWidth": "424px"
      }} viewable mdxType="Image" />
    </div>
    <div className="guideline-do" style={{
      "background": "var(--color-success-light)",
      "padding": "var(--spacing-s)",
      "marginBottom": "var(--spacing-layout-2-xs)",
      "maxWidth": "600px"
    }}>
  <p>
    <IconCheck style={{
          marginRight: 'var(--spacing-3-xs)',
          verticalAlign: 'middle',
          color: 'var(--color-success)'
        }} size="s" mdxType="IconCheck" />
    <strong>Fields combining several inputs together with a fieldset, can be placed next to each other.</strong>
  </p>
  <div>
    For example:
    <ul>
      <li>Two to three radio buttons with short labels</li>
      <li>First name / last name</li>
      <li>Date (day/month/year)</li>
      <li>Time (hour/minute/second)</li>
    </ul>
  </div>
  <Image src="/images/patterns/form/building/form-layout-horizontal@2x.png" alt="Fields combining several inputs together with a fieldset" style={{
        "maxWidth": "468px"
      }} viewable mdxType="Image" />
    </div>
    <div className="guideline-do" style={{
      "background": "var(--color-success-light)",
      "padding": "var(--spacing-s)",
      "marginBottom": "var(--spacing-layout-2-xs)",
      "maxWidth": "600px"
    }}>
  <p>
    <IconCheck style={{
          marginRight: 'var(--spacing-3-xs)',
          verticalAlign: 'middle',
          color: 'var(--color-success)'
        }} size="s" mdxType="IconCheck" />
    <strong>Group form-wide action buttons together.</strong> Best practice is to place action buttons at the end of the
    form, aligned to left and ordered by importance.
  </p>
  <ol>
    <li>Submit (primary action)</li>
    <li>Secondary actions</li>
  </ol>
  <Image src="/images/patterns/form/building/form-layout-actions-form@2x.png" alt="Align form-wide action buttons at the end of the form" style={{
        "maxWidth": "432px"
      }} viewable mdxType="Image" />
    </div>
    <div className="guideline-do" style={{
      "background": "var(--color-success-light)",
      "padding": "var(--spacing-s)",
      "marginBottom": "var(--spacing-layout-2-xs)",
      "maxWidth": "600px"
    }}>
  <p>
    <IconCheck style={{
          marginRight: 'var(--spacing-3-xs)',
          verticalAlign: 'middle',
          color: 'var(--color-success)'
        }} size="s" mdxType="IconCheck" />
    <strong>
      Align supplementary action buttons related to singe form control or fieldset after or bellow the control
    </strong>
  </p>
  <Image src="/images/patterns/form/building/form-layout-actions-field@2x.png" alt="Supplementary action" style={{
        "maxWidth": "432px"
      }} viewable mdxType="Image" />
    </div>
    <div className="guideline-do" style={{
      "background": "var(--color-success-light)",
      "padding": "var(--spacing-s)",
      "marginBottom": "var(--spacing-layout-2-xs)",
      "maxWidth": "600px"
    }}>
  <p>
    <IconCheck style={{
          marginRight: 'var(--spacing-3-xs)',
          verticalAlign: 'middle',
          color: 'var(--color-success)'
        }} size="s" mdxType="IconCheck" />
    <strong>
      Use <InternalLink href="/foundation/design-tokens/spacing" mdxType="InternalLink">spacing tokens</InternalLink> for grouping form controls with proximity and
      separation
    </strong>
  </p>
  <ul>
    <li>Use smaller spacing between controls that belong together and larger spacing between groups of controls</li>
  </ul>
  <Image src="/images/patterns/form/building/form-layout-spacing@2x.png" alt="Use spacing for grouping form controls" style={{
        "maxWidth": "432px"
      }} viewable mdxType="Image" />
    </div>
    <div className="guideline-do" style={{
      "background": "var(--color-success-light)",
      "padding": "var(--spacing-s)",
      "marginBottom": "var(--spacing-layout-2-xs)",
      "maxWidth": "600px"
    }}>
  <p>
    <IconCheck style={{
          marginRight: 'var(--spacing-3-xs)',
          verticalAlign: 'middle',
          color: 'var(--color-success)'
        }} size="s" mdxType="IconCheck" />
    <strong>
      Align form controls to <InternalLink href="/foundation/guidelines/grid" mdxType="InternalLink">column grid</InternalLink>.
    </strong>
  </p>
  <Image src="/images/patterns/form/building/form-layout-grid@2x.png" alt="Align fields to column grid" style={{
        "maxWidth": "432px"
      }} viewable mdxType="Image" />
    </div>
    <div className="guideline-do" style={{
      "background": "var(--color-success-light)",
      "padding": "var(--spacing-s)",
      "marginBottom": "var(--spacing-layout-2-xs)",
      "maxWidth": "600px"
    }}>
  <p>
    <IconCheck style={{
          marginRight: 'var(--spacing-3-xs)',
          verticalAlign: 'middle',
          color: 'var(--color-success)'
        }} size="s" mdxType="IconCheck" />
    <strong>Adjust field width according to preferred input.</strong>
  </p>
  <Image src="/images/patterns/form/building/form-layout-width@2x.png" alt="Adjust field width according to preferred input" style={{
        "maxWidth": "432px"
      }} viewable mdxType="Image" />
    </div>
    <hr></hr>
    <h3 {...{
      "id": "2-grouping-related-controls",
      "style": {
        "position": "relative"
      }
    }}>{`2. Grouping related controls`}<a parentName="h3" {...{
        "href": "#2-grouping-related-controls",
        "aria-label": "2 grouping related controls permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p><strong parentName="p">{`Form controls that perform a common task or share a common purpose `}<em parentName="strong">{`should`}</em>{` be grouped together`}</strong>{` within a 'fieldset' element and labelled with a 'legend' element, which describes the purpose of the form control group.`}</p>
    <p>{`Fieldsets make it easier for users to comprehend forms and setup dialogs more quickly, and help screenreader users to understand where form groups begin and end, and the purpose and number of elements in a related group. Many assistive technologies will also use the legend element as if it is a part of the label of each control inside the corresponding fieldset element.`}</p>
    <p>{`The fieldset element is used for grouping:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Set of form controls that together perform a task yielding a single value.`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`The fieldset legend describes the selection made while the radio button or checkbox labels describe the options available.`}</li>
          <li parentName="ul">{`Assistive technologies will also announce the number of selections inside the group when the fieldset receives focus.`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Set of form controls that are closely related but yield separate values.`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`For example text inputs like “Name” (first + last), “Birthday” (day + month + year), “Address” (street address + zip + city)`}</li>
        </ul>
      </li>
    </ul>
    <Image src="/images/patterns/form/building/form-grouping@2x.png" alt="Grouping with fieldsets" style={{
      "maxWidth": "432px"
    }} viewable mdxType="Image" />
    <hr></hr>
    <h3 {...{
      "id": "3-segmenting-with-section-headings",
      "style": {
        "position": "relative"
      }
    }}>{`3. Segmenting with section headings`}<a parentName="h3" {...{
        "href": "#3-segmenting-with-section-headings",
        "aria-label": "3 segmenting with section headings permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`To make the structure of the form even more understandable, `}<strong parentName="p">{`form controls that share a common theme should be grouped into sub-sections with a heading.`}</strong>{` Especially long forms benefit from segmenting the flow into smaller segments.`}</p>
    <Image src="/images/patterns/form/building/form-segmenting@2x.png" alt="Segmenting with section headings" style={{
      "maxWidth": "432px"
    }} viewable mdxType="Image" />
    <div className="guideline-do" style={{
      "background": "var(--color-success-light)",
      "padding": "var(--spacing-s)",
      "marginBottom": "var(--spacing-layout-2-xs)",
      "maxWidth": "600px"
    }}>
  <div className="guideline-do-label" style={{
        "color": "var(--color-success)",
        "marginBottom": "var(--spacing-s)"
      }}>
    <IconCheck style={{
          marginRight: 'var(--spacing-3-xs)',
          verticalAlign: 'middle'
        }} size="s" mdxType="IconCheck" />
    <strong>Do</strong>
  </div>
  <ul style={{
        "marginBottom": "0"
      }}>
    <li>Use the proper heading level: If the form heading is h1, the segment headings must be h2.</li>
    <li>
      The segment heading should describe how the form controls are related to each other and to the form as a whole
      <ul>
        <li>For example “Personal information” or “Payment information”.</li>
      </ul>
    </li>
  </ul>
    </div>
    <hr></hr>
    <h3 {...{
      "id": "4-dividing-long-forms-into-separate-steps",
      "style": {
        "position": "relative"
      }
    }}>{`4. Dividing long forms into separate steps`}<a parentName="h3" {...{
        "href": "#4-dividing-long-forms-into-separate-steps",
        "aria-label": "4 dividing long forms into separate steps permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`Filling a long form can be daunting task for the user. One way to make a long form seem less like a chore is by progressive disclosure – dividing the form into separate steps that are shown one page at a time.`}</p>
    <p>{`This approach also makes fixing validation errors easier, when the inputted data can be validated and fixed step by step, and thus fever errors are shown at a same time.`}</p>
    <p>
  <StatusLabel type="error" style={{
        marginRight: 'var(--spacing-3-xs)'
      }} mdxType="StatusLabel">
    Coming soon
  </StatusLabel>
  <strong>The navigation component for multi-page forms has not yet been designed or implemented in HDS.</strong>
    </p>
    <Image src="/images/patterns/form/building/form-steps@2x.png" alt="Dividing long forms into separate steps" style={{
      "maxWidth": "500px"
    }} viewable mdxType="Image" />
    <div className="guideline-do" style={{
      "background": "var(--color-success-light)",
      "padding": "var(--spacing-s)",
      "marginBottom": "var(--spacing-layout-2-xs)",
      "maxWidth": "600px"
    }}>
  <div className="guideline-do-label" style={{
        "color": "var(--color-success)",
        "marginBottom": "var(--spacing-s)"
      }}>
    <IconCheck style={{
          marginRight: 'var(--spacing-3-xs)',
          verticalAlign: 'middle'
        }} size="s" mdxType="IconCheck" />
    <strong>Do</strong>
  </div>
  <ul style={{
        "marginBottom": "0"
      }}>
    <li>
      <strong>
        A set of radio buttons or checkboxes <em>must</em> be grouped together with a fieldset.
      </strong>
    </li>
    <li>
      <strong>Divide the form in approximately even sized steps</strong>
      <ul>
        <li>Show one theme per page. For example Contact information – payment information – selected options etc.</li>
        <li>Give each step a short, descriptive heading with a h1 element.</li>
      </ul>
    </li>
    <li>
      <strong>List all the steps needed to complete the form in a separate form navigation </strong>
      <ul>
        <li>Show the heading of each step in the navigation</li>
        <li>Show the form navigation through the filling process.</li>
      </ul>
    </li>
    <li>
      <strong>Show the user their progress page by page as they fill the form</strong>
      <ul>
        <li>Let the user navigate back to previous steps</li>
        <li>Disable next steps before all previous steps have been filled (and validated)</li>
      </ul>
    </li>
    <li>
      <strong>Validate the data after each step and let the user fix errors before proceeding to the next step</strong>
      <ul>
        <li>
          The user <em>should</em> only be allowed to proceed to next steps by clicking the "Next step" action button,
          to ensure the data is validated.
        </li>
        <li>
          If the user navigates back to a previously filled step and changes any input value, the following steps
          <em> should</em> be disabled in the form navigation.
        </li>
      </ul>
    </li>
  </ul>
    </div>
    <hr></hr>
    <h2 {...{
      "id": "keyboard-navigation",
      "style": {
        "position": "relative"
      }
    }}>{`Keyboard navigation`}<a parentName="h2" {...{
        "href": "#keyboard-navigation",
        "aria-label": "keyboard navigation permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <p>{`Navigating between form components with keyboard not only helps users with using assistive technologies but makes filling forms easier for all users. Switching between mouse for navigation and keyboards for inputting values can become tedious, especially if the forms has lots of text input fields.`}</p>
    <p>{`For this reason it is essential to ensure that individual controls receive focus in a logical order:`}</p>
    <ul>
      <li parentName="ul">{`When user first enters a form give focus to the firs element by default, so users can tab through all the form elements.`}</li>
      <li parentName="ul">{`Proceed focusing individual controls from left to right – top to bottom.`}</li>
    </ul>
    <h3 {...{
      "id": "an-example-focus-order-for-a-single-page-form",
      "style": {
        "position": "relative"
      }
    }}>{`An example focus order for a single page form:`}<a parentName="h3" {...{
        "href": "#an-example-focus-order-for-a-single-page-form",
        "aria-label": "an example focus order for a single page form permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <Image src="/images/patterns/form/building/form-focus-single@2x.png" alt="An example focus order for a single page form" style={{
      "maxWidth": "500px"
    }} viewable mdxType="Image" />
    <ol>
      <li parentName="ol">{`Form main heading`}</li>
      <li parentName="ol">{`Form instructions`}</li>
      <li parentName="ol">{`First segment heading, fieldset or control`}</li>
      <li parentName="ol">{`Fields/fieldsets from left to right – top to bottom
…`}</li>
      <li parentName="ol">{`Submit button`}</li>
    </ol>
    <hr></hr>
    <h3 {...{
      "id": "an-example-focus-order-for-a-multi-page-form",
      "style": {
        "position": "relative"
      }
    }}>{`An example focus order for a multi page form:`}<a parentName="h3" {...{
        "href": "#an-example-focus-order-for-a-multi-page-form",
        "aria-label": "an example focus order for a multi page form permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <Image src="/images/patterns/form/building/form-focus-multi@2x.png" alt="An example focus order for a multi page form" style={{
      "maxWidth": "500px"
    }} viewable mdxType="Image" />
    <ol>
      <li parentName="ol">{`Form navigation`}</li>
      <li parentName="ol">{`Form heading`}</li>
      <li parentName="ol">{`Form instructions`}</li>
      <li parentName="ol">{`First segment heading, fieldset or control`}</li>
      <li parentName="ol">{`Fields/fieldsets from left to right – top to bottom
…`}</li>
      <li parentName="ol">{`Next page or Submit button`}</li>
      <li parentName="ol">{`Previous page button`}</li>
    </ol>
    <div className="guideline-do" style={{
      "background": "var(--color-success-light)",
      "padding": "var(--spacing-s)",
      "marginBottom": "var(--spacing-layout-2-xs)",
      "maxWidth": "600px"
    }}>
  <div className="guideline-do-label" style={{
        "color": "var(--color-success)",
        "marginBottom": "var(--spacing-s)"
      }}>
    <IconCheck style={{
          marginRight: 'var(--spacing-3-xs)',
          verticalAlign: 'middle'
        }} size="s" mdxType="IconCheck" />
    <strong>Do</strong>
  </div>
  <ul style={{
        "marginBottom": "0"
      }}>
    <li>
      <strong>Check the order in which individual controls in the form receive focus</strong>
      <ul>
        <li>
          By default the focus order follows the DOM order, so in most cases checking that the controls are in the right
          order in the DOM tree is sufficient.
        </li>
        <li>If the focus order needs to deviate from DOM order, it can be customised with tabindex attributes.</li>
      </ul>
    </li>
    <li>
      <strong>
        Pay special attention to focus order when dynamically changing page content, adding or removing form controls,
        or clearing values
      </strong>
      <ul>
        <li>
          As a rule of thumb, the change <em>should</em> be made downstream in the focus order, not upstream, so the
          user can find the change by navigating forward.
        </li>
        <li>
          New content <em>should</em> be added after the current focus point.
        </li>
        <li>
          When current focus point is removed, focus <em>should</em> return to the last heading or legend of the
          fieldset.
        </li>
        <li>Focus must not leave the form when the content is dynamically changed.</li>
      </ul>
    </li>
    <li>
      In multi page forms, when the user proceeds to the next or previous page, focus <em>should</em> be directed to the
      heading of the form or current form step, instead of the beginning of the entire page. This helps the user to keep
      focus on the task at hand.
    </li>
    <li>
      tabindex="-1" can defined for the heading, fieldset legend or other element where the focus <em>should</em> be
      directed after a dynamic content change or page change.
      <ul>
        <li>
          Not necessary in forms embedded into content. In these cases focus can start from page heading and follow the
          DOM order.
        </li>
      </ul>
    </li>
  </ul>
    </div>
    <div className="guideline-dont" style={{
      "background": "var(--color-error-light)",
      "padding": "var(--spacing-s)",
      "marginBottom": "var(--spacing-layout-2-xs)",
      "maxWidth": "600px"
    }}>
  <div className="guideline-dont-label" style={{
        "color": "var(--color-error)",
        "marginBottom": "var(--spacing-s)"
      }}>
    <IconError style={{
          marginRight: 'var(--spacing-3-xs)',
          verticalAlign: 'middle'
        }} size="s" mdxType="IconError" />
    <strong>Don't</strong>
  </div>
  <ul style={{
        "marginBottom": "0"
      }}>
    <li>
      <strong>Any form controls or essential information must not be placed after the “Submit” button.</strong>
      <ul>
        <li>Important “small print” about the form as a text content or an anchor link to page.</li>
        <li>A checkbox for approving terms and conditions</li>
      </ul>
    </li>
    <li>
      In multi step forms, any form controls or essential information should not be placed after a “Next page” button
      <ul>
        <li>
          Exception: since navigating forward to the next step of the form is the most common action the user makes when
          reaching the end of a page, “Previous page” and other supplementary actions like “Clear form” can be placed
          after it.
        </li>
      </ul>
    </li>
  </ul>
    </div>
    <hr></hr>
    <h2 {...{
      "id": "gender-neutrality-in-forms",
      "style": {
        "position": "relative"
      }
    }}>{`Gender-neutrality in forms`}<a parentName="h2" {...{
        "href": "#gender-neutrality-in-forms",
        "aria-label": "gender neutrality in forms permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "asking-for-gender",
      "style": {
        "position": "relative"
      }
    }}>{`Asking for gender`}<a parentName="h3" {...{
        "href": "#asking-for-gender",
        "aria-label": "asking for gender permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`When asking for the user's gender, `}<inlineCode parentName="p">{`Other`}</inlineCode>{` and `}<inlineCode parentName="p">{`Do not want to answer`}</inlineCode>{` options must be provided. If these options are ordered, use order `}<inlineCode parentName="p">{`Woman → Man → Other → Do not want to answer`}</inlineCode>{` since it is statistically the most probable. Using the `}<InternalLink href="/components/selection-group" mdxType="InternalLink">{`Selection group component`}</InternalLink>{` with radio buttons is recommended for this purpose.`}</p>
    <Image src="/images/patterns/form/building/form-asking-for-gender-example@2x.png" alt="An example of asking the user's gender" style={{
      "maxWidth": "432px"
    }} viewable mdxType="Image" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      